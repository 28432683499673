* {
  color: yellow;
}

body {
  width: 100vw;
  height: 100vh;
  background: url(https://www.flywallpaper.com/mypics/max/17/171872_star-wars-star-backgrounds.jpg)
}

header.header {

  height: 10vh;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
  margin: auto;
  font-size: 24px;
  font-weight: 600;
  width: 65%;
}

form {

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

input {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: black;
  color: yellow;
}

select {
  background-color:black;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

button {
  background: black;
}